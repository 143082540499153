input, textarea, select {
  background: transparent;
  color: $accent;
  border: 1px solid $accent;
  border-radius: 0;
  padding: 10px;
  margin: 5px 0;
  font: inherit;
  appearance: none;

  &:focus, :active {
    border-color: $color;
    outline: 1px solid $color;
  }

  &:active {
    box-shadow: none;
  }
}

select {
  background: $background;

  option {
    background: $background;
  }
}

::placeholder {
  color: transparentize($accent, .5);
}

input {
  &[type="checkbox"] {
    vertical-align: middle;
    padding: 10px;
    box-shadow: inset 0 0 0 3px $background;

    &:checked {
      background: $accent;
    }
  }
}
