$accent: #FF6266;
@import "variables";

@import "font";
@import "buttons";
@import "form";

@import "header";
@import "menu";
@import "logo";
@import "main";
@import "post";
@import "pagination";
@import "footer";

@import "prism";
@import "syntax";
@import "code";
@import "terms";
@import "gist";
